@import '@/styles/variables.scss';

$cutoff: 600px;

.Header {
  display: grid;
  grid-template-columns: 1fr max-content;
  &.ThreeColumns {
    @include container-min($cutoff + 1px) {
      grid-template-columns: 1fr max-content max-content;
    }
  }
  align-items: center;
  margin-bottom: 23px;
  column-gap: 10px;
  row-gap: 14px;
}

.Title {
  display: flex;
  align-items: center;
}

.TitleText {
  margin-bottom: 23px;
  .Header &,
  &.Flush {
    margin-bottom: 0;
  }
}

h1.TitleText,
h2.TitleText,
h3.TitleText {
  margin-top: 0;
}

.TitleTextExtraSmall {
  @include font(17px, 'BOLD');
}

.TitleTextSmall {
  @include font(19px, 'BOLD');
}

.TitleTextMedium {
  @include font(19px, 'BLACK');
}

.TitleTextLarge {
  @include font(25px, 'BLACK');
}

.Center {
  .ThreeColumns & {
    @include container-max($cutoff) {
      grid-row: 2;
      grid-column: 1 / -1;
    }
  }
}

.Right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.TitleTextLargeMedium {
  @include font(25px, 'BLACK');
  @include large {
    @include font(19px, 'BLACK');
  }
}
